import { Component, ContentChild, Input } from '@angular/core';
import { ListViewLayout } from '../list-view/list-view.layout';
import { NgFor } from '@angular/common';



@Component({
    selector: 'simple-list-view',
    templateUrl: './simple-list-view.layout.html',
    styleUrls: ['./simple-list-view.layout.scss'],
    standalone: true,
    imports: [NgFor]
})
export class SimpleListViewLayout extends ListViewLayout {

}



