
<mat-form-field class="field">
  <mat-label>{{ label }}</mat-label>
  <mat-select [formControl]="formControl">
    <mat-option *ngFor="let item of items" [value]="item.id">
      {{ item['title'] }}
    </mat-option>
  </mat-select>
            <mat-icon matSuffix>{{suffix}}</mat-icon>
          <mat-hint i18n>{{hint}}</mat-hint>
</mat-form-field> 
