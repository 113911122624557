import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SimpleFormControl } from '../simple-form-control/simple-form.control';
import { MatRadioModule } from '@angular/material/radio';
import { NgFor } from '@angular/common';
import { MatHint } from '@angular/material/form-field';

@Component({
    selector: 'option-radio',
    templateUrl: './option-radio.control.html',
    styleUrl: './option-radio.control.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OptionRadioControl),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => OptionRadioControl),
            multi: true,
        },
    ],
    standalone: true,
    imports: [
        MatRadioModule,
        FormsModule,
        ReactiveFormsModule,
        NgFor,
        MatHint,
    ],
})
export class OptionRadioControl extends SimpleFormControl {
  @Input() items!: { code: string; label: string }[];
}
