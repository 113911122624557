import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SimpleFormControl } from 'src/app/hah-foundation/blocks/form-controls/simple-form-control/simple-form.control';
import { IModel, IVuModel } from 'src/app/hah-foundation/models/record.model';
import { MatFormField, MatLabel, MatSuffix, MatHint } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { NgFor } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'option-select-control',
    templateUrl: './option-select.control.html',
    styleUrls: ['./option-select.control.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OptionSelectControl),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => OptionSelectControl),
            multi: true,
        },
    ],
    standalone: true,
    imports: [
        MatFormField,
        MatLabel,
        MatSelect,
        FormsModule,
        ReactiveFormsModule,
        NgFor,
        MatOption,
        MatIcon,
        MatSuffix,
        MatHint,
    ],
})
export class OptionSelectControl extends SimpleFormControl {
  @Input() items!: IModel[];
}
