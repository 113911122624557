//Angular
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//Blocks
import { PaginatorBlock } from './blocks/paginator/paginator.block';
import { CardBlock } from './blocks/card/card.block';
import { SearchBlock } from './blocks/search/search.block';

import { RecordEditorCommandsBlock } from './blocks/operation-transactions/record-editor-commands/record-editor-commands.block';
import { RecordViewerCommandsBlock } from './blocks/operation-transactions/record-viewer-commands/record-viewer-commands.block';

import { CancelButton } from './blocks/form-controls/buttons/cancel-button/cancel.button';
import { DeleteButton } from './blocks/form-controls/buttons/delete-button/delete.button';
import { UpdateButton } from './blocks/form-controls/buttons/update-button/update.button';
import { YesButton } from './blocks/form-controls/buttons/yes-button/yes.button';
import { NoButton } from './blocks/form-controls/buttons/no-button/no.button';
import { EditButton } from './blocks/form-controls/buttons/edit-button/edit.button';
import { NewButton } from './blocks/form-controls/buttons/new-button/new.button';
import { AttachButton } from './blocks/form-controls/buttons/attach-button/attach.button';

//Directives
import { CrossFieldValidatorDirective } from './directives/cross-field-validator/cross-field-validator.directive';
import { EmailValidatorDirective } from './directives/email-validator/email-validator.directive';
import { NameValidatorDirective } from './directives/name-validator/name-validator.directive';

//Components

//Styling
import { RecordViewLayout } from './layouts/record-view/record-view.layout';
import { RecordEditLayout } from './layouts/record-edit/record-edit.layout';
import {
  ListViewLayout,
  RecordViewerDirective,
} from './layouts/list-view/list-view.layout';
import { SimpleRecordLayout } from './layouts/simple-record/simple-record.layout';


import { RecordListDirective } from './layouts/abstract-search-nav/abstract-search-nav.layout';
import { SimpleListViewLayout } from './layouts/simple-list-view/simple-list-view.layout';

import { ChildRecordEditLayout } from './layouts/child-record-edit/child-record-edit.layout';

import { InputControl } from './blocks/form-controls/input-control/input.control';
import { OptionSelectControl } from './blocks/form-controls/option-select-control/option-select.control';
import { OptionRadioControl } from './blocks/form-controls/option-radio-control/option-radio.control';

@NgModule({
    providers: [],
    imports: [
        EmailValidatorDirective,
        CrossFieldValidatorDirective,
        NameValidatorDirective,
        RecordViewerDirective,
        RecordListDirective,
        SearchBlock,
        CardBlock,
        PaginatorBlock,
        RecordViewerCommandsBlock,
        RecordEditorCommandsBlock,
        CancelButton,
        DeleteButton,
        EditButton,
        YesButton,
        NoButton,
        UpdateButton,
        NewButton,
        AttachButton,
        ListViewLayout,
        SimpleRecordLayout,
        OptionSelectControl,
        InputControl,
        OptionRadioControl,
        RecordViewLayout,
        RecordEditLayout,
        ChildRecordEditLayout,
        SimpleListViewLayout,
    ],
    exports: [
        EmailValidatorDirective,
        CrossFieldValidatorDirective,
        NameValidatorDirective,
        SearchBlock,
        CardBlock,
        ListViewLayout,
        SimpleRecordLayout,
        RecordViewerDirective,
        RecordListDirective,
        RecordViewerCommandsBlock,
        PaginatorBlock,
        RecordEditorCommandsBlock,
        DeleteButton,
        EditButton,
        YesButton,
        NoButton,
        UpdateButton,
        NewButton,
        AttachButton,
        OptionSelectControl,
        OptionRadioControl,
        InputControl,
        RecordViewLayout,
        RecordEditLayout,
        ChildRecordEditLayout,
        SimpleListViewLayout,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HAHFoundationModule {}
