import { Component } from '@angular/core';
import { RecordEditLayout } from '../record-edit/record-edit.layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'child-record-edit',
    templateUrl: './child-record-edit.layout.html',
    styleUrl: './child-record-edit.layout.scss',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule],
})
export class ChildRecordEditLayout extends RecordEditLayout {
}
