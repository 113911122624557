import { Injectable, Input, OnDestroy, OnInit } from '@angular/core';

import { ControlValueAccessor, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Injectable()
export abstract class SimpleFormControl
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input() label?: string;
  @Input() suffix?: string;
  @Input() hint?: string = "Initial Hint";

  formControl = new FormControl();
  valueSubscription!: Subscription;
  onChange = (value: any) => {};

  ngOnInit() {
    this.valueSubscription = this.formControl.valueChanges.subscribe((v) => {
      return this.onChange && this.onChange(v);
    });
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (value: any) => void): void {}

  writeValue(value: any): void {
    if (this.formControl && this.formControl.value != value)
      this.formControl.setValue(value, { emitEvent: false });
  }

  ngOnDestroy() {
    this.valueSubscription.unsubscribe();
  }
  validate() {
    return false;
  }
}
