import { Component } from '@angular/core';
import { AbstractRootComponent } from 'src/app/hah-foundation/classes/abstract-root-component.class';

import {
  IdNotify,
  OperationType,
} from 'src/app/hah-foundation/models/id-notify.model';
import { RecordObserverService } from 'src/app/hah-foundation/services/record-observer/record-observer.service';



@Component({
    selector: 'attach-button',
    templateUrl: './attach.button.html',
    styleUrl: './attach.button.scss',
    standalone: true,
})
export class AttachButton extends AbstractRootComponent {

  onAttach() {
    this.notifyChainParent(new IdNotify({ operation: OperationType.attach }));
  }
}
