<form [formGroup]="editFormGroup!" #editFormDir="ngForm" >
    <div class="card">
        <div class="card-header">
            <h3 class="card-title"><ng-content select="[card-title]"></ng-content></h3>
        </div>
        <div class="card-body">
            <ng-content select="[record-editor-fields]"></ng-content>
        </div>
    </div>
    <div class="card-footer">
    </div>
</form>